@import '../core-globals.scss';

.DynamicRollups {
    &__container {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        padding: 20px 20px 20px 20px;
    }

    &__actions-container,
    &__settings-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        .Button {
            svg {
                margin-right: 8px;
                margin-left: 0;
            }
        }
    }

    &__filterContainer {
        padding: 0px 10px;
        box-shadow: 0px 10px 20px $borderTableColor;
    }

    &__error {
        display: flex;
        font-size: 16px;
        color: $errorColor;
        min-height: 100px;
        justify-content: center;
        align-items: center;
    }

    &__no-data {
        width: 530px;
        margin: 40px auto 0 auto;
        font-style: italic;
        font-size: 1.4em;
        line-height: 2em;
        text-align: center;
        padding: 30px;
        border: 2px dashed $borderColor;
        background-color: $backgroundColor;
    }
}
