@import '../core-globals.scss';

.SourceFile {
    padding: 16px;
    position: relative;
    display: grid;
    font-family: $nikeFont;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0 0 7px rgba($primaryColor, 0.15);
    background-color: #fdfdfd;
    grid-template-rows: auto;
    grid-template-columns: 48px auto 180px;
    grid-template-areas:
        'icon name meta'
        'icon title meta';
    &--narrow {
        grid-template-columns: 48px auto;
        grid-template-areas:
            'icon name'
            'icon meta';
    }
    column-gap: 8px;
    row-gap: 8px;
    transition: box-shadow 0.2s ease-in-out;
    color: $lightPrimaryColor;
    span {
        pointer-events: none;
    }
    &__fileIcon {
        grid-area: icon;
        font-size: 48px;
        color: $successColor;
    }
    &__title {
        grid-area: title;
    }
    &__meta {
        grid-area: meta;
        white-space: nowrap;
    }
    &__progress {
        position: absolute;
        width: 100%;
        bottom: 0;
        .progress {
            background-color: rgba($tertiaryColor, 0.1);
            border-radius: 0 0 0.25rem 0.25rem;
            height: 0.75rem;
            &-bar {
                background-color: rgba($tertiaryColor, 1);
            }
        }
    }
    &__filename {
        grid-area: name;
        font-size: 1.4em;
        color: $primaryColor;
        word-break: break-all;
    }
    &--deleting {
        pointer-events: none;
        opacity: 0.5;
    }
    &--processing {
        background-color: darken($secondaryColor, 1%);
    }
    &--processing &__filename,
    &--processing &__fileIcon {
        color: $lightPrimaryColor;
    }
    &__dragOverlay {
        display: none;
        font-size: 1.4em;
        justify-content: center;
        align-items: center;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba($secondaryColor, 0.8);
    }

    .SourceFileOptions {
        transform: translateX(100%);
        transition: transform 0.25s ease-out;
        span {
            pointer-events: initial;
        }
    }
    &--dragged &__dragOverlay {
        color: $tertiaryColor;
        display: flex;
    }
    &--dragged-error &__dragOverlay {
        color: $errorColor;
    }
    &:hover {
        box-shadow: 0 0 5px rgba($primaryColor, 0.1);
        .SourceFileOptions {
            transform: translateX(0);
        }
    }
    &--errors {
        background-color: $errorColor;
    }
    &--errors &__meta,
    &--errors &__fileIcon,
    &--errors &__filename {
        color: $secondaryColor;
    }
}
