@import '../core-globals.scss';

.assortment-cluster-header {
    width: 48px;
    height: 220px;
    text-transform: uppercase;
    transform: rotate(225deg);
    transform-origin: 135% 49%;
    padding: 25px 10px 0px 20px;
    border-right: 1px solid #e5e5e5;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    position: relative;
    z-index: 2;

    &__button {
        margin-top: 8px;
    }

    &__stores {
        display: flex;
    }

    &__container {
        height: 100%;
        writing-mode: vertical-lr;
    }

    .out-of-variance {
        color: red;
    }

    .within-variance {
        color: $successColor;
    }

    &__option-counts {
        font-family: $regularFont;
        letter-spacing: 0.1em;
        font-size: 0.9em;
    }
}

.assortment-cluster-header__stores {
    .assortment-table-header__header-label {
        border: none;
        font-family: $regularFont;
        font-size: 0.9em;
    }
}
