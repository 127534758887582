.top-styles-report-page {
    margin: 0 auto;

    .main-filters {
        padding: 0.5rem 0 0 0;

        &__filter {
            margin-left: 0;
            margin-right: 2rem;

            & > div {
                padding: 0;
            }
        }
    }
}
