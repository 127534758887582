@import '../../core-globals.scss';

.FilterControl {
    &__button {
        cursor: pointer;
        &:hover {
            outline: none;
            color: $tertiaryColor;
        }
    }
    &__buttonContainer {
        position: relative;
        span {
            position: absolute;
            top: -16px;
            right: -8px;
            cursor: pointer;
            &:hover {
                color: $activeColor;
            }
        }
    }
    &__container {
        padding: 20px;
    }
}
