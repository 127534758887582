@import '../core-globals.scss';

.StepIndicator {
    &__circle {
        &-background {
            stroke: $secondaryColor;
        }
        &-progress {
            fill: none;
            stroke: $secondaryColor;
            stroke-linecap: round;
            stroke-linejoin: round;
            transition: stroke-dashoffset 0.5s ease 0s;
        }
        &-text {
            font-size: 1.2em;
            font-family: $nikeFont;
            fill: $secondaryColor;
        }
    }
    .svg-inline--fa {
        vertical-align: middle;
        path {
            transform: scale(0.3);
            transform-origin: 50% 50%;
        }
    }
    .check {
        color: $secondaryColor;
    }
    color: $secondaryColor;
    font-size: 1em;
    &--state {
        &-processing {
            .StepIndicator__circle-background {
                fill: $tertiaryColor;
            }
        }
        &-waiting {
            .StepIndicator__circle-background {
                fill: $tertiaryColor;
            }
        }
        &-completed {
            .StepIndicator__circle-background {
                fill: $successColor;
            }
        }
        &-failed {
            .StepIndicator__circle-background {
                fill: $errorColor;
            }
        }
        &-custom,
        &-idle {
            color: $lightPrimaryColor;
            .StepIndicator__circle-background {
                fill: $idleColor;
            }
        }
    }
    &--spinning {
        animation: rotating 4s linear infinite;
    }
}
