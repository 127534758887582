@import '../core-globals.scss';
.root {
    @media (min-width: $break-small) {
        min-width: 50% !important;
    }
}
.card {
    width: 100%;
    background-color: $warningBackgroundColor !important;
}
.typography {
    color: $primaryColor;
    font-family: $nikeFont !important;
}
.actionRoot {
    padding: 8px 8px 8px 16px;
    justify-content: space-between;
}
.closeButton {
    margin-left: auto;
}
.button {
    padding: 0;
    text-transform: none;
}
