@import '../core-globals.scss';

.assortment-store-header,
.assortment-lock-header {
    transform: rotate(225deg);
    text-transform: uppercase;
    transform-origin: 131% 52%;
    padding: 20px 10px 0px 20px;
    border-right: 1px solid #e5e5e5;
    position: relative;
    z-index: 2;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 48px;
    writing-mode: vertical-lr;
    .out-of-variance {
        color: red;
    }

    .within-variance {
        color: $successColor;
    }

    &__option-counts {
        font-family: $regularFont;
        letter-spacing: 0.1em;
        font-size: 0.9em;
    }
}
