@import '../core-globals.scss';

.UserForm {
    legend {
        font-family: $nikeFont;
        margin-bottom: 20px;
        font-size: 30px;
    }
    label {
        font-weight: bold;
        margin-right: 12px;
    }
    &__fields-container {
        margin-top: 20px;
    }
    &__info-message {
        margin-left: 8px;
        font-style: italic;
        font-weight: normal;
    }
    &__email,
    &__channelAccess {
        font-weight: bold;
        margin-bottom: 20px;
        span {
            margin-left: 12px;
            font-weight: initial;
            background-color: $borderColor;
            padding: 5px 12px;
            display: inline-block;
            min-width: 300px;
        }
    }
    &__channelAccess {
        margin: 20px 0 0 0;
        span {
            min-width: initial;
        }
    }

    &__submit-buttons {
        margin-top: 30px;
        margin-bottom: 60px;
        text-align: right;
    }
    &__success-message {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(#fff, 0.8);
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $successColor;
        font-family: $nikeFont;
        font-size: 1.4em;
    }
    &__error-message {
        margin: 40px;
    }
    &__submit-error-message {
        color: $errorColor;
    }
}
