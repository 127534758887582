.MarginImpactTable {
    table {
        width: 100%;
    }
    td {
        height: 38px; // Increase height to give room for loading spinner
        &.MarginImpactRow {
            white-space: nowrap;
        }
        input {
            max-width: 100px;
        }
    }

    &__header-like4like {
        width: 250px;
    }
}
