@import '../core-globals.scss';

.ExportAssortment {
    ul {
        list-style: none;
        padding: 0;
        li {
            padding-left: 20px;
            a {
                margin: 5px 5px;
                svg {
                    margin-right: 8px;
                }
            }
        }
    }
}
