@import '../core-globals.scss';

$borderStyle: 1px solid $borderColor;

.AssortmentDetailCheckbox {
    font-size: 12px;
    width: 48px;
    height: 49px;
    top: -8px;
    padding: 5px;
    display: inline-block;
    border-left: $borderStyle;
    border-bottom: $borderStyle;
    text-align: center;
    background-color: #fff;
    position: relative;
    min-width: 36px;
    overflow: hidden;

    &__stores {
        display: flex;
    }

    &__label {
        width: 100%;
        height: 100%;
        cursor: pointer;
        /* Hide the browser's default checkbox */
        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;

            &:checked ~ .checkmark {
                /* Show the checkmark when checked */
                &:after {
                    display: block;
                }
            }
            &:hover {
                ~ .checkmark {
                    background-color: $borderTableColor;
                }
            }
            &:disabled ~ .checkmark {
                background-color: #eee;
                cursor: default;
            }
        }

        /* Create a custom checkbox */
        .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            background-color: #fff;
            width: 100%;
            height: 100%;

            &:after {
                /* Create the checkmark/indicator (hidden when not checked) */
                content: '';
                position: absolute;
                display: none;

                /* Style the checkmark/indicator */
                left: 20px;
                top: 12px;
                width: 10px;
                height: 20px;
                border: solid #000;
                border-width: 0 3px 3px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
            }
        }
        &--isPlannerAssortment {
            input ~ .checkmark {
                background-color: $manualAdjustmentColor;
            }
            input:hover ~ .checkmark {
                background-color: darken($manualAdjustmentColor, 3%);
            }
        }
        &--isPartnerAssortment {
            input ~ .checkmark {
                background-color: $partnerAdjustmentColor;
            }
            input:hover ~ .checkmark {
                background-color: darken($partnerAdjustmentColor, 3%);
            }
        }
    }

    &__locked {
        position: absolute;
        right: 4px;
        z-index: 1;
    }

    &__allCheck {
        width: 48px;
        &:hover {
            background-color: $borderTableColor;
        }

        button {
            margin-top: 4px;
            margin-left: -2px;
            height: 30px;
            border: none;
            display: flex;
            align-items: center;
            width: 100%;
            cursor: pointer;
            svg {
                width: 60px;
            }
            &:focus,
            &:active {
                outline: none;
            }
        }
        &--disabled {
            background-color: #eee;

            &:hover {
                background-color: #eee;
            }
            button {
                cursor: default;
            }
        }
    }

    &:last-child {
        border-right: $borderStyle;
    }
}
