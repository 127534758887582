@import '../core-globals.scss';

.Notes {
    &__icon {
        font-size: 18px;
        cursor: pointer;
        text-align: center;
        border: none;
        background-color: transparent;
        margin-top: -3px;

        &:focus,
        &:hover {
            outline: none;
            color: $tertiaryColor;
        }

        &--empty {
            color: $inputBorderColor;
        }
    }

    &__textarea {
        resize: none;
        height: 180px;
        width: 550px;
        overflow: auto;
        margin: 5px;
        padding: 12px;
        font-family: inherit;
        font-size: 15px;
        border: 1px solid #aaa;
        box-shadow: inset 2px 2px 2px 0px #dddddd;
    }
}
