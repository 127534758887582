$primaryColor: #000000;
$secondaryColor: #ffffff;
$successColor: rgb(0, 166, 100);
$errorColor: rgb(215, 29, 79);
$warningColor: rgb(255, 180, 0);
$tertiaryColor: rgb(2, 127, 168);
$lightPrimaryColor: rgba($primaryColor, 0.4);
$manualAdjustmentColor: #ebf7fd;
$partnerAdjustmentColor: #ffebe3;
$activeColor: #ab003c;

$backgroundColor: #f7f7f7;
$borderColor: #e5e5e5;
$borderTableColor: #dee2e6;
$inputBorderColor: #cccccc;
$idleColor: #dfe7ea;
$titleColor: #999;
$starColor: rgb(255, 200, 0);

$warningBackgroundColor: mix($warningColor, $secondaryColor, 10%);

$errorBackgroundColor: mix($errorColor, $secondaryColor, 25%);

$buttonBackground: rgb(34, 34, 34);
$buttonBackgroundHover: rgb(52, 152, 219);
$buttonColor: $secondaryColor;

$break-stacked-6-filters: 1740px;
$break-xlarge: 1280px;
$break-large: 991px;
$break-medium: 767px;
$break-small: 575px;

$headerHeight: 48px;
$impersonationBannerHeight: 30px;
$testEnvBannerHeight: 20px;

$nikeFont: 'Trade Gothic for Nike 365 BdCn';
$nikeFuturaFont: 'Nike Futura';
$regularFont: Helvetica, Arial, sans-serif;

// this :export directive is webpack magic, allowing these variables to be imported in TypeScript
:export {
    primaryColor: $primaryColor;
    inputBorderColor: $inputBorderColor;
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes slideInAnimation {
    0% {
        transform: translateX(-30px);
    }
    100% {
        transform: translateX(0);
    }
}

.fa-pulse {
    display: inline-block;
    animation: pulse 2s infinite linear;
}

@keyframes pulse {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.2;
    }
    100% {
        opacity: 1;
    }
}

.fa-angle-down {
    right: -11px;
}
