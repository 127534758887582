@import '../core-globals.scss';

.DummyCodeMaterial {
    position: relative;
    td {
        vertical-align: middle;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    td.DummyCodeMaterial {
        &--noPadding {
            padding: 0;
            overflow: visible;
        }
        &--overflowVisible {
            overflow: visible;
        }
        &__image {
            background-color: $secondaryColor;
            text-align: center;
            position: relative;
        }
    }
    &--active {
        background-color: rgba(0, 0, 0, 0.05);
        .DummyCodeMaterial__image {
            border-left: 1px solid $lightPrimaryColor;
        }
        .DummyCodeMaterial__replace {
            border-right: 1px solid $lightPrimaryColor;
        }
        &.DummyCodeMaterial__original td {
            border-top: 1px solid $lightPrimaryColor;
        }
    }
    &__feedback td {
        text-align: center;
        border-left: 1px solid $lightPrimaryColor;
        border-right: 1px solid $lightPrimaryColor;
    }
    &__input {
        padding: 3px 5px;
        min-width: 110px;
    }
    &__new {
        td {
            border-bottom: 1px solid $lightPrimaryColor;
            position: relative;
        }
    }
    &__replace {
        text-align: center;
        button {
            margin-bottom: 0;
            margin-top: -2px;
        }
    }
    &__replace-icon {
        position: absolute;
        left: 40px;
        top: -8px;
        font-size: 16px;
        color: $primaryColor;
    }
}
