@import '../core-globals.scss';

.SettingsButton {
    font-size: 24px;
    cursor: pointer;
    text-align: center;
    border: none;
    background-color: transparent;
    padding: 5px 3px;

    &:focus,
    &:hover {
        outline: none;
        color: $tertiaryColor;
    }
}
