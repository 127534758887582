@import '../core-globals.scss';

.FileDropzone {
    padding: 10px;
    font-family: $nikeFont;
    border: 2px dashed $borderColor;
    border-radius: 10px;
    background-color: $secondaryColor;
    color: $lightPrimaryColor;
    display: flex;
    font-size: 1.4em;
    height: 160px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: color 0.2s ease-in-out border-color 0.2s ease-in-out;

    .button {
        display: inline-block;
        margin: 0 6px;
        padding: 8px 10px;
        background-color: $lightPrimaryColor;
        border-radius: 4px;
        color: $secondaryColor;
        font-weight: lighter;
        &:hover {
            background-color: $tertiaryColor;
        }
    }

    &:hover {
        color: $primaryColor;
        border-color: $lightPrimaryColor;
    }

    &--active {
        color: $successColor;
    }

    &--reject {
        color: $errorColor;
    }

    &--disabled,
    &--disabled:hover {
        border: 2px dashed $idleColor;
        color: $idleColor;
        cursor: no-drop;
        .button,
        .button:hover {
            background-color: $idleColor;
        }
    }
}
