.MaterialImage {
    &__thumbnail {
        margin-left: 2px;
        margin-right: 2px;

        // The following prevents the "alt" copy from breaking the design when image src is not loading
        max-width: 35px;
        overflow: hidden;
    }

    &__default-image {
        width: 200px; // The default image is very big; this reduces it somewhat
    }

    &__popover {
        max-width: initial; // Resets bootstrap's "max-width:300"

        .popover-body {
            padding: 3px;
        }
    }
}
