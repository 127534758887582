@import '../core-globals';

.pagination {
    display: flex;
    margin-bottom: 10px;
    align-items: baseline;
    color: $lightPrimaryColor;

    &__total-items-info {
        align-self: flex-end;
    }

    &__page-slider {
        display: flex;
        flex: 1;
        align-items: inherit;
        justify-content: flex-end;
        line-height: normal;

        &-item {
            margin-left: 10px;
            margin-bottom: 0;
        }
    }

    &__page-input {
        padding: 5px;
        min-width: 50px;
        text-align: right;
    }
}
