@import '../core-globals.scss';

.dot {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    display: inline-block;
    margin-left: 2px;
    margin-right: 2px;
    background-color: #6c757d;

    &--large {
        height: 12px;
        width: 12px;
        margin-left: 5px;
        margin-right: 5px;
    }

    &--info {
        background-color: $tertiaryColor;
    }

    &--success {
        background-color: $successColor;
    }

    &--warning {
        background-color: $warningColor;
    }

    &--error {
        background-color: $errorColor;
    }
}
