@import '../core-globals.scss';

.UserList {
    list-style-type: none;
    padding: 0;
    li {
        display: grid;
        grid-template-columns: 300px 1fr 100px 130px;
        column-gap: 12px;
        padding: 2px 0;
        &:hover {
            background-color: $backgroundColor;
        }
    }
    &__email {
        font-weight: bold;
        font-family: $nikeFont;
        font-size: 1.2em;
        text-transform: uppercase;
        overflow: auto;
        text-overflow: ellipsis;
        a {
            text-decoration: none;
        }
    }
    &__summary {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    &__error {
        padding: 12px 0;
    }

    &__impersonate .Button,
    &__deactivateImpersonation .Button {
        min-width: 130px;
    }

    &__edit,
    &__impersonate {
        a {
            text-decoration: none;
        }
    }
}
