@import '../core-globals.scss';

@mixin responsive-sidebar-width {
    width: 330px;

    @media only screen and (max-width: $break-large) {
        width: 200px;
    }
}

.header {
    box-shadow: inset 0 -1px 0 0 $borderColor;
    position: fixed;
    top: 0;
    background-color: $secondaryColor;
    z-index: 4; // Ensures menu overlays any other z-index used for sticky/scrolling elements
    width: 100%;
    justify-content: center;

    &__historical-season {
        background-color: #eeeeee;
    }

    &--partner-portal {
        position: initial;
        margin-bottom: 10px;
    }

    &__logo {
        display: flex;
        align-items: center;
        height: $headerHeight;
        @include responsive-sidebar-width;
    }

    .Logo__swoosh {
        @media only screen and (max-width: $break-large) {
            width: 50px;
        }
    }

    &__dropdown {
        display: flex;
        margin: 0 20px;
        @media only screen and (max-width: $break-large) {
            margin: 0 10px;
        }
    }

    &__navigation {
        display: flex;
        align-items: center;
        flex-shrink: 0;
    }

    &__spacer {
        display: flex;
        justify-content: flex-end;
        @include responsive-sidebar-width; // match the width of navigation items on the left so menu items stay centered
    }

    &__side {
        display: flex;
        align-items: center;
    }

    &__environment__warning {
        width: 100%;
        background-color: #f76201;
        font-family: $nikeFont;
        font-size: 14px;
        color: $secondaryColor;
        height: 20px;
        justify-content: center;
        align-items: center;
        display: flex;
    }
    #application-selector,
    #season-selector,
    #channel-selector {
        font-size: 12px;

        @media only screen and (min-width: $break-xlarge) {
            font-size: 14px;
        }
    }

    &__container {
        display: flex;
        justify-content: space-between;
        padding: 0 10px;
        height: $headerHeight;
    }

    &__impersonation__warning {
        width: 100%;
        background-color: $activeColor;
        font-family: $nikeFont;
        font-size: 14px;
        color: $secondaryColor;
        display: flex;
        justify-content: space-between;
        padding: 0 10px 0 10px;
        align-items: center;
        height: 30px;
    }
}
