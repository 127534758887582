@import '../core-globals.scss';
@import './BuyplanAggregatedMaterialDetailRow.scss';

.BuyPlanPresentationStocksTooltip {
    // Change colors to resemble a normal Bootstrap <Tooltip />
    background-color: $primaryColor;
    max-width: none;

    .popover-body {
        color: $secondaryColor;
    }

    .arrow {
        &:after,
        &:before {
            border-right-color: $primaryColor;
        }
    }

    &--error {
        border-color: $errorColor;
        background-color: $errorBackgroundColor;

        .popover-body {
            color: $primaryColor;
        }

        .arrow {
            &:after {
                border-right-color: $errorBackgroundColor;
            }
            &:before {
                border-right-color: $errorColor;
            }
        }
    }

    &--warning {
        border-color: $warningColor;
        background-color: $warningBackgroundColor;

        .popover-body {
            color: $primaryColor;
        }

        .arrow {
            &:after {
                border-right-color: $warningBackgroundColor;
            }
            &:before {
                border-right-color: $warningColor;
            }
        }
    }
}
