@import '../core-globals.scss';

.ImportSeasonalHindsight {
    &__uploadLabel {
        display: inline-block;
    }

    &__title {
        float: left;
    }
    &__warning {
        color: $titleColor;
        margin-left: 10px;
        margin-top: 15px;
        float: left;
    }
    .FileDropzone {
        clear: left;
    }
}
.ImportSalesTargets {
    &__buttonContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
