.SettingsReportColumns {
    &__groupList {
        list-style: none;
        margin: 20px 0;
        padding: 0;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        column-gap: 10px;
        margin: 10px 0 20px 25px;
    }

    &__groupName {
        .checklabel {
            font-weight: bold;
        }
    }
}
