@import './core-globals.scss';

.no-support {
    display: flex;
    flex-flow: column;
    align-items: center;
    a {
        margin: 40px 0 20px;
    }
    p {
        background-color: $backgroundColor;
        padding: 30px;
        font-family: $nikeFont;
        font-size: 1.4em;
        line-height: 1.8em;
        text-align: center;
        border: 1px solid $borderColor;
        svg {
            color: $errorColor;
            font-size: 2em;
            margin-bottom: 10px;
        }
    }
}
