.SettingsDisplayColumns {
    &__list {
        list-style: none;
        margin: 20px 0;
        padding: 0;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        column-gap: 10px;
    }
}
