@import '../core-globals.scss';

.ExportBuyPlan {
    ul {
        list-style: none;
        padding: 0;
        li {
            padding-left: 20px;
            a {
                margin: 5px 5px;
                svg {
                    margin-right: 8px;
                }
            }
        }
    }

    &__withImageContainer {
        margin: 20px;
        .checklabel {
            font-size: 16px;
            font-weight: bold;
            color: #999;
        }

        .checkmark {
            width: 18px;
            height: 18px;
            border: 1px solid #e5e5e5;
        }
    }
}
