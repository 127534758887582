@import '../core-globals.scss';

.SourceFileErrors {
    background-color: #ffffff;
    border-radius: 0 0 0.25rem 0.25rem;
    padding: 15px 30px 15px;
    margin: 0 10px;
    margin-top: -3px;
    box-shadow: 0 0 7px rgba($primaryColor, 0.15), inset 0 7px 7px -7px rgba($primaryColor, 0.35);
    h3 {
        color: $errorColor;
        margin-bottom: 12px;
    }
    &__actions {
        text-align: right;
    }
}
