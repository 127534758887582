@import '../core-globals.scss';

.Authorizer {
    text-align: center;
    padding: 50px 30px;
    font-family: $nikeFont;
    font-size: 20px;

    h2 {
        margin-top: 50px;
        text-transform: uppercase;
    }

    a {
        color: $tertiaryColor;
    }

    &__error-block {
        text-align: center;
        margin: 100px auto;
        font-size: 16px;
    }

    @media all and (max-width: $break-xlarge) {
        h3 {
            font-size: 16px;
        }
    }
}
