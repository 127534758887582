@import '../../core-globals.scss';

.DynamicRollupsTable {
    &__header-data {
        display: flex;
        align-items: center;
        height: 100%;

        &__divider {
            border-left: 1px solid $borderColor;
        }
    }
}
