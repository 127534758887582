@import '../core-globals.scss';

.BuyplanAggregatedMaterialDetailRow {
    &__cell {
        padding: 5px;
        line-height: 22px;
        &--align-right {
            text-align: right;
        }
    }
    &__rateOfSale,
    &__sellThrough,
    &__afPercentage,
    &__marginImpact,
    &__ukSalesPercentage,
    &__weeksOnSale {
        padding-top: 0;
        padding-bottom: 0;
        input {
            width: 100%;
            text-align: inherit;
        }
    }
    &__openToBuyUnits,
    &__aaOpenToBuyUnits,
    &__afOpenToBuyUnits {
        padding-top: 0;
        padding-bottom: 0;
        input {
            width: calc(100% - 12px); // allow space for StoreOpenToBuyUnitsDot
            text-align: inherit;
        }
    }
    &__lastYear {
        padding: 0 5px;
    }
    &__reviewedStatus {
        padding: 5px 15px 5px 5px;
        text-align: right;
        width: 100px;
    }
    &__partnerName {
        padding: 5px 15px 5px 5px;
        text-align: left;
        font-weight: bold;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 120px;
    }
    &__storeName {
        padding: 5px 15px 5px 5px;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 250px;
    }
    &__storeNumber {
        overflow: hidden;
        text-overflow: ellipsis;
        width: 74px;
        text-align: right;
    }
    &__store {
        display: flex;
        align-items: center;
        justify-content: end;
    }
    &__star {
        color: $starColor;
        margin-right: 4px;
    }
    &__delete-button {
        transform: rotate(45deg);
        border: none;
        cursor: pointer;
        background-color: transparent;

        &:focus {
            outline: none;
            svg {
                border-radius: 50%;
                box-shadow: 0 0 5px $tertiaryColor;
            }
        }
        &:hover {
            svg {
                color: $errorColor;
            }
        }
        &--disabled {
            cursor: not-allowed;
            color: $lightPrimaryColor;
            svg {
                color: $lightPrimaryColor;
            }
            &:hover {
                svg {
                    color: $lightPrimaryColor;
                }
            }
        }
    }
    &__loading-delete {
        display: inline;
        padding-left: 10px;
    }
}
