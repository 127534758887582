@import '../core-globals.scss';

.ViewBuyplanList {
    overflow-x: hidden;
    position: relative;

    &__item {
        position: absolute;
        outline: 0;
    }

    &__container {
        display: flex;
        flex-direction: row;
    }

    &__fixed-container {
        contain: content;
        z-index: 1;
        box-shadow: 8px 0 5px -4px rgba(0, 0, 0, 0.1);
        .ViewBuyplanList__item {
            // Hide the Chrome scrollbar for the fixed container list but don't disable it; we still want it to scroll.
            &::-webkit-scrollbar {
                display: none;
            }
        }
    }

    &__scrollable-container {
        flex: 0 0 auto;
        overflow-x: scroll;
    }

    &__aggregatedMaterials {
        width: 100%;
        height: calc(100vh - 220px);
    }
    &__column {
        display: inline-block;
        padding: 2px 5px;
    }
    &__header,
    &__subheader {
        padding: 0 10px;
        text-transform: uppercase;
        height: 32px;
        line-height: 22px;
        border-bottom: 1px solid $borderColor;

        & > div {
            display: flex;
            padding: 5px;
        }

        & .ViewBuyplanList__image {
            padding: 0;
            position: relative;
            left: -5px;
        }

        & .ViewBuyplanList__lastYear {
            color: $lightPrimaryColor;
            font-family: $nikeFont;
        }
    }
    &__subheader {
        box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
    }
    &__header {
        font-family: $nikeFont;
        display: flex;
        height: 40px;
        align-items: center;
    }
    &--loading {
        .ViewBuyplanList__aggregatedMaterials {
            opacity: 0.4;
            pointer-events: none;
            user-select: none;
        }
    }
    &__header-cell {
        &--align-right {
            text-align: right;
        }
        &--highlighted {
            background-color: $borderColor;
        }
        &--toggles {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-around;
        }
        &--lastYear {
            color: rgba(0, 0, 0, 0.4);
        }
    }
    &__noResults {
        font-style: italic;
        font-size: 1.4em;
        line-height: 2em;
        text-align: center;
        padding: 30px;
        border: 2px dashed $borderColor;
        justify-self: center;
        align-self: center;
        background-color: $backgroundColor;
    }
    &__favoriteStore {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
}
