@import '../core-globals.scss';

.LastYearData {
    &__cell {
        display: inline-block;
        color: $lightPrimaryColor;
        padding: 0 5px;

        // overflow styling
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: top; // adjust baseline change when setting inline-box's overflow to hidden

        &--align-right {
            text-align: right;
        }
    }

    &__lastYearReferenceType {
        text-align: center;
        height: 32px;

        &--button {
            border: none;
            &--clickable {
                cursor: pointer;
            }
        }

        svg {
            vertical-align: text-bottom;
        }
    }

    &__modal-content {
        display: flex;
        flex: 1;
        align-items: center;
    }
}
