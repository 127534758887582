@import '../../core-globals.scss';

.filter {
    margin-bottom: 20px;
    &:last-child {
        margin-bottom: 0;
    }
    .zero {
        background: none;
        border: 0;
        border-color: transparent;
        margin-left: 20px;
        margin-top: 5px;
        margin-bottom: 10px;
    }
    .header,
    .title {
        margin-bottom: 21px;
    }

    .title {
        font-size: 24px;
        line-height: 1;
        min-height: 23px;
        text-transform: uppercase;
    }

    .header,
    .title {
        margin-bottom: 21px;
    }

    .labelContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 12px;
    }

    .label {
        font-style: normal;
        font-weight: 400;
        font-family: 'Trade Gothic for Nike 365 BdCn';
        text-transform: uppercase;
        font-size: 16px;
    }

    .labelSubheader {
        font-style: normal;
        font-weight: 400;
        font-family: 'Trade Gothic for Nike 365 BdCn';
        text-transform: uppercase;
        font-size: 13px;
        color: rgba(0, 0, 0, 0.2);
    }

    .heading {
        text-transform: uppercase;
        font-size: 15px;
        padding: 21px 0 5px;
        cursor: pointer;
        position: relative;
        border: none;
        background-color: transparent;
        width: 100%;
        text-align: left;
        outline: 0;

        .glyph {
            position: absolute;
            right: 8px;
            top: 21px;
        }

        [class*='glyph--'] {
            display: inline-block;
            overflow: hidden;
            text-align: left;
            text-indent: 100%;
            white-space: nowrap;
            // position: relative;
            vertical-align: middle;
            width: 1em;
            height: 1em;
            border: 0;
            background: transparent;
            user-select: none;
            outline: none;

            &:before {
                -webkit-font-smoothing: antialiased;
                display: inline-block;
                position: absolute;
                top: 50%;
                left: 0;
                width: 100%;
                text-align: center;
                text-indent: 0;
                line-height: 1;
                margin-top: -0.7em;
                font-size: 17px;
                font-weight: bold;
                user-select: none;
                outline: none;
            }
        }
        .glyph--plus:before {
            content: '+';
        }
        .glyph--minus:before {
            content: '-';
        }
    }

    .selects {
        display: block;
        visibility: visible;
        max-height: 999em;
        line-height: 24px;
        overflow: hidden;

        ul {
            margin: 7px 0px;
            list-style: none;
            padding: 0;
            max-height: 300px;
            overflow-y: auto;
            li {
                margin: 0;
                padding: 0;

                text-decoration: none;
                color: #111;
                display: block;
                position: relative;

                label {
                    margin-right: 8px;
                    cursor: pointer;
                }
            }
        }
        &.singleSelect .checkbox-container .checkmark {
            border-radius: 50%;
        }
    }

    .dateRangeContainer {
        margin-bottom: 20px;
    }

    .datePickerContainer {
        max-width: 90px;
        display: inline-block;
        margin-right: 10px;
        max-width: 85px;
    }

    [data-css-1tdptiz] {
        padding: 0;
        #undefined-filters-row {
            margin-right: 10px;
            #undefined-input-start-filters-row,
            #undefined-input-end-filters-row {
                & > div:first-child {
                    width: 100% !important;

                    #undefined-input-start-input,
                    #undefined-input-end-input {
                        font-style: normal;
                        font-weight: 300;
                        font-family: 'Trade Gothic for Nike 365 BdCn' !important;
                        font-size: 13px;
                    }

                    & > div > div {
                        font-style: normal;
                        font-weight: 300;
                        font-family: 'Trade Gothic for Nike 365 BdCn';
                        font-size: 13px;
                    }
                }
            }
        }
    }
}
