@import '../core-globals.scss';

.ViewAssortment {
    position: relative;
    background-color: #fff;
    height: calc(100vh - 95px);
    .main-filters {
        &__filter {
            padding: 1rem 0 0 0;
        }
    }
}
