@import '../core-globals.scss';

.TargetsRow {
    td {
        vertical-align: middle;
    }
    .Button {
        vertical-align: bottom;
    }
    td.TargetsRow {
        &--minimal-padding {
            padding: 4px;

            > * {
                margin-right: 0.3rem;
            }
        }

        .error {
            padding-top: 4px;
        }

        .fa-check {
            color: $successColor;
        }

        .fa-times {
            color: $errorColor;
        }
    }

    .svg-loader {
        display: inline-block;
    }

    &__input {
        text-align: right;
    }

    &__like4like {
        &--select {
            display: inline-block;
            width: 250px;
        }
        &--history {
            padding: 5px;
            min-width: 14px;
            display: inline-block;
        }
    }

    &__status {
        text-align: center;
        width: 40px;
    }
}
