@import '../../core-globals.scss';

.DynamicRollupsTable {
    overflow-x: hidden;
    position: relative;
    margin-top: 20px;

    &__item {
        position: absolute;
        outline: 0;
    }

    &__container {
        display: flex;
        flex-direction: row;
    }

    &__fixed-container {
        contain: content;
        z-index: 1;
        border-right: 3px solid $borderColor;
        .DynamicRollupsTable__item {
            // Hide the Chrome scrollbar for the fixed container list but don't disable it; we still want it to scroll.
            &::-webkit-scrollbar {
                display: none;
            }
        }
    }

    &__scrollable-container {
        flex: 0 0 auto;
        overflow-x: scroll;
    }

    &__header,
    &__subheader {
        padding: 0 10px;
        text-transform: uppercase;
        height: 32px;
        line-height: 22px;
        border-bottom: 1px solid $borderColor;

        & > div {
            display: flex;
        }
    }
    &__subheader {
        box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
        height: 64px;
    }
    &__header {
        font-family: $nikeFont;
        display: flex;
        min-height: 42px;
        height: auto;
    }
}
