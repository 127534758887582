@import '../../core-globals.scss';

.top-styles-report {
    h1 {
        margin-top: 0;
        padding-top: 10px;
    }
    &--filter-container {
        padding: 0 1rem;
        box-shadow: 0px 10px 20px $borderTableColor;
        margin-bottom: 20px;
    }

    &--error-message {
        width: 530px;
        margin: 40px auto 0 auto;
        font-style: italic;
        font-size: 1.4em;
        line-height: 2em;
        text-align: center;
        padding: 30px;
        border: 2px dashed $borderColor;
        background-color: $backgroundColor;
    }

    &__table {
        &-container {
            padding: 0 1rem;
            overflow-x: auto;
        }

        &--last-year {
            margin-top: 50px;
        }
    }
}
