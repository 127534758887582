@import '../../core-globals.scss';

.topStyles-row {
    &__text {
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__numbers {
        text-align: right;
    }

    &__divider {
        border-left: 1px solid $borderTableColor;
        text-align: right;
    }
}
