@import '../core-globals.scss';

.Logo {
    display: flex;
    align-items: center;
    align-content: center;
    text-decoration: none;

    &--buyplan {
        .Logo__name {
            margin-left: 5px;
            font-family: 'Nike Futura';
            letter-spacing: 2px;
            font-style: italic;
        }
    }

    &--black h1 {
        color: $primaryColor;
    }

    &--white h1 {
        color: $secondaryColor;
    }

    svg {
        fill: currentColor;
    }

    &__swoosh {
        width: 60px;
        fill: currentColor;
    }

    &__name {
        text-transform: uppercase;
        font-size: 24px;
        color: $primaryColor;
        margin: 0 0 0 5px;

        &--hide-small-screen {
            display: none;
        }

        @media only screen and (min-width: $break-xlarge) {
            display: block;
        }
    }

    &:focus,
    &:hover,
    &:active {
        text-decoration: none;
    }
}
