@import './core-globals.scss';
@import './react-table.scss';

@font-face {
    font-family: 'Trade Gothic for Nike 365 BdCn';
    src: url(../assets/fonts/TradeGothicforNike365-BdCn.eot);
    src: url(../assets/fonts/TradeGothicforNike365-BdCn.ttf) format('truetype');
}

@font-face {
    font-family: 'Nike Futura';
    font-style: normal;
    font-weight: 400;
    src: url(../assets/fonts/nike-futura-extra-bold-v200.eot),
        url(../assets/fonts/nike-futura-extra-bold-v200.eot?#iefix) format('embedded-opentype'),
        url(../assets/fonts/nike-futura-extra-bold-v200.woff2) format('woff2'),
        url(../assets/fonts/nike-futura-extra-bold-v200.woff) format('woff'),
        url(../assets/fonts/nike-futura-extra-bold-v200.ttf) format('truetype'),
        url(../assets/fonts/nike-futura-extra-bold-v200.svg#nike-futura-extra-bold-v200);
}

html,
body,
#root {
    margin: 0;
    padding: 0;
    height: 100%;
    line-height: 1em;
    font-family: $regularFont;
    font-size: 14px;
}

input:disabled {
    background-color: $borderColor;
}

button {
    background: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
th,
button {
    font-family: $nikeFont;
    line-height: 1.4em;
}

th {
    text-transform: uppercase;
}

h1 {
    color: $primaryColor;
    font-size: 30px;
    margin-top: 10px;
    flex: 2;
    .svg-loader {
        display: inline-block;
    }
}

h2 {
    color: $primaryColor;
    font-size: 28px;
}

h3 {
    color: inherit;
    font-size: 20px;
    margin-top: 10px;
}

h4 {
    color: inherit;
    font-size: 18px;
}

h5 {
    color: inherit;
    font-size: 16px;
}

h6 {
    color: inherit;
    font-size: 14px;
}

p {
    line-height: 1.4em;
}

ul,
ol {
    line-height: 1.4em;
}

a {
    color: $primaryColor;
    text-decoration: underline;
    &:hover {
        color: $tertiaryColor;
    }
}

div[role='dialog'] {
    background-color: rgba(0, 0, 0, 0.7);
}

.nopadding {
    padding: 0 !important;
}

.centered {
    text-align: center;
}

.warning {
    color: $warningColor;
}
.error {
    color: $errorColor;
}

@media all and (max-width: $break-xlarge) {
    h1 {
        font-size: 26px;
    }
    h2 {
        font-size: 18px;
    }
}

@keyframes fade {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
