@import '../components/core-globals.scss';

.buyplan-routes {
    padding-top: $headerHeight;
    min-height: calc(100% - 110px);
    > div {
        animation: fadeInAnimation 0.3s;
    }
    &--withImpersonationBanner {
        padding-top: $headerHeight + $impersonationBannerHeight;
    }
    &--withTestEnvBanner {
        padding-top: $headerHeight + $testEnvBannerHeight;
    }
    &--bothBanners {
        padding-top: $headerHeight + $impersonationBannerHeight + $testEnvBannerHeight;
    }
}
