@import './core-globals.scss';

.MuiPaper-root.MuiDialog-paper {
    background-color: $secondaryColor !important;
}
.MuiPickersDay-root.Mui-selected {
    background-color: $primaryColor !important;
}
.MuiDialogActions-root .MuiButton-text {
    color: $primaryColor !important;
}
.Mui-error {
    color: $errorColor !important;
}

.MuiSelect-select.MuiSelect-select {
    margin-top: -2px;
}
