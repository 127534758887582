@import '../../core-globals.scss';

.main-filters {
    display: flex;
    flex-flow: row wrap;
    align-content: center;
    margin-top: 20px;

    &__filter {
        width: 260px;
        margin: auto;
        padding-bottom: 20px;

        &-single {
            width: 256px;
            &-label {
                margin-bottom: 10px;
            }
            &-option {
                height: 30px;
                padding: 6px 0 7px;
                border-bottom: 1px solid $inputBorderColor;
            }
        }
    }

    // nr-vamp-xl-select-field uses "disabled=true" even on enabled fields
    input:disabled {
        background-color: transparent;
    }
}

div[id$='-popper'] {
    z-index: 4; // Overlay sticky table columns
}
