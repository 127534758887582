@import '../core-globals.scss';

.SyncMaterialPrices {
    &__button {
        cursor: pointer;
        border: none;
        color: $lightPrimaryColor;
        width: 30px;
        &:focus {
            outline: none;
            color: $tertiaryColor;
        }
    }
    &__icon {
        &:hover {
            color: $tertiaryColor;
        }
    }
    &__icon--success {
        color: $successColor;
        &:hover {
            color: $successColor;
            cursor: default;
        }
    }
    &__icon--error {
        color: $errorColor;
        &:hover {
            color: $errorColor;
            cursor: not-allowed;
        }
    }
    &__icon--loading {
        color: $lightPrimaryColor;
        &:hover {
            color: $lightPrimaryColor;
            cursor: default;
        }
    }
}
