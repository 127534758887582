@import '../core-globals.scss';

.Warning {
    background-color: rgba($warningColor, 0.8);
    line-height: 1.3em;
    display: flex;
    margin-bottom: 8px;
    padding: 20px;
    border-radius: 10px;

    svg {
        color: $warningBackgroundColor;
        margin: 2px 18px 0 0;
    }
}
