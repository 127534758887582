@import '../core-globals.scss';

.SettingsFavoriteStores {
    &__stores-list {
        ul {
            max-height: 500px;
            overflow-y: scroll;
            font-size: 18px;
            margin: 0 8px 0 0;
            padding: 0;
        }

        li {
            font-size: 18px;
            list-style: none;
            margin: 0;
            padding: 0;
            border-top: 1px solid $inputBorderColor;
        }
    }

    &__reset-favorites {
        margin-bottom: 20px;
    }

    &__star {
        font-size: 20px;
        color: $idleColor;
        margin-right: 10px;
    }

    &__favorite-link {
        border: none;
        padding: 8px;
        display: block;
        cursor: pointer;
        outline: none;
        width: 100%;
        text-align: left;

        &:focus {
            outline: none;
        }

        &:hover {
            .SettingsFavoriteStores__star {
                color: $tertiaryColor;
            }
        }

        &--selected {
            .SettingsFavoriteStores__star {
                color: $starColor;
            }

            &:hover {
                .SettingsFavoriteStores__star {
                    color: $starColor;
                }
            }
        }
    }
}
