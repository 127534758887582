@import '../../core-globals.scss';

.DynamicRollupsRow {
    &__cell {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 5px;
        height: 36px;
        line-height: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &__text {
        &--negative {
            color: $errorColor;
        }
    }

    &__divider {
        border-right: 1px solid $borderTableColor;
    }
}
