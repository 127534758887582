@import '../core-globals';

.AssortmentSettings {
    button {
        &:focus {
            outline: none;
            color: $tertiaryColor;
        }
    }

    &__display-columns {
        margin: 25px 10px;
    }

    &__tab-title {
        font-family: $nikeFont;
    }
}
