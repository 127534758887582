@import '../../core-globals.scss';

.BuyplanDocumentation {
    &__Navigation {
        font-family: $nikeFont;
        position: sticky;
        top: 120px;
        font-size: 1.2em;
        line-height: 1.4em;
        top: 20px;
        a {
            display: block;
            text-decoration: none;
            margin-bottom: 12px;
            &.active,
            &.active h2 {
                color: $tertiaryColor;
            }
        }
    }

    img:not(.Logo__swoosh) {
        max-width: 100%;
        margin: 20px;
        box-shadow: 0 0 15px rgba($color: #000000, $alpha: 0.3);
    }

    &__SectionTitle {
        color: $lightPrimaryColor;
        margin-bottom: 10px;
    }

    table td {
        vertical-align: middle;
    }

    .ImportBuyPlanFiles {
        &__fileSection {
            margin-top: 2rem;
            margin-bottom: 2rem;
            padding-top: 2rem;
            border-top: 1px solid rgba(0, 0, 0, 0.1);
        }
    }
    .AllocationsTable__notes {
        min-width: 100px; // keep notes section from collapsing on small screens
    }
    .table__salesTargetsDescription {
        th,
        td {
            vertical-align: top;
        }
    }
    .ViewingOptionCounts {
        &__warning {
            &--red {
                color: $errorColor;
            }
            &--green {
                color: $successColor;
            }
        }
    }
    .ExchangeRatesDocumentation {
        &__planHindsight {
            padding-left: 0;
        }
        img.ExchangeRatesDocumentation__smallImage {
            max-width: 550px;
        }
    }
}
