@import '../core-globals.scss';

.TargetsTable {
    overflow: auto;
    display: block;
    height: 500px;

    table {
        width: 100%;
        position: relative;
        border-collapse: inherit;

        thead {
            th {
                top: 0;
                white-space: nowrap;
                position: sticky;
                z-index: 2;
                background-color: white;
                border-top: 1px solid $inputBorderColor;
                border-bottom: 1px solid $inputBorderColor;
            }
        }

        td {
            height: 38px; // Increase height to give room for loading spinner
            &.TargetsRow {
                white-space: nowrap;
            }
            input {
                max-width: 100px;
            }
        }
    }

    .table-bordered {
        border: 0;
    }

    &__headers {
        font-family: $nikeFont;
        text-transform: uppercase;

        td {
            padding: 10px;
        }
    }
}
