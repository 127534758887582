@import '../core-globals.scss';

.BuyplanAggregatedMaterialDetail {
    margin: 0 10px 0 15px;
    table-layout: fixed;

    th {
        padding-left: 5px;
    }

    td {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    &__header {
        height: 28px;
        line-height: 28px;
        font-family: $nikeFont;

        td {
            padding: 0 5px;
        }

        & > div {
            padding: 5px;
            &:first-child {
                text-align: right;
            }
        }
    }

    &__modal {
        &-annotation {
            font-size: 13px;
            font-style: italic;
            color: rgba($primaryColor, 0.8);
        }
    }
}
