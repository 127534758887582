@import '../core-globals.scss';

.ImportFiles {
    padding-bottom: 20px;
    &__files {
        padding: 0;
        margin: 0;
        list-style: none;
    }
    &__file {
        margin-bottom: 10px;
        margin-top: 10px;
    }

    &__file {
        &--animation {
            &-enter {
                transition: opacity 0.5s ease-in, max-height 0.5s ease-in;
                opacity: 0;
                max-height: 0;

                &-active {
                    max-height: 100px;
                    opacity: 1;
                }
                &-done {
                    max-height: initial;
                }
            }
            &-exit {
                transition: opacity 0.5s ease-out, max-height 0.5s ease-out;
                opacity: 1;
                max-height: 100px;
                &-active {
                    opacity: 0;
                    max-height: 0;
                }
                &-done {
                    max-height: initial;
                }
            }
        }
    }

    .FileDropzone {
        height: 120px;
    }
}
