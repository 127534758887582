@import '../core-globals.scss';

.navigation {
    a,
    .nav {
        text-align: center;
        display: inline-block;
        font-family: $nikeFont;
        text-transform: uppercase;
        text-decoration: none;
        color: $primaryColor;
        padding: 16px 8px;
        font-size: 16px;

        &:active,
        &.active {
            border-bottom: 2px solid $primaryColor;
            text-decoration: none;
        }
        @media only screen and (max-width: $break-large) {
            font-size: 14px;
        }
    }

    span {
        position: relative;
        ul {
            display: none;
            position: absolute;
            z-index: 99999;
            left: 50%;
            margin-left: -90px;
            top: 27px;
            background-color: $secondaryColor;
            padding: 6px 0;
            list-style-type: none;
            width: 180px;
            box-shadow: 0 0 7px rgba($primaryColor, 0.15);

            a {
                display: block;
                padding: 4px;

                @media only screen and (min-width: $break-large) {
                    padding: 8px;
                }

                &:active,
                &.active {
                    border-bottom: none;
                    span {
                        border-bottom: 2px solid $primaryColor;
                    }
                }
            }
        }

        &:hover {
            ul {
                display: block;
            }
        }
    }
}
