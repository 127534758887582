@import '../core-globals.scss';

.buyplan-partners-total {
    font-size: 10px;

    &__container {
        margin: 30px 50px 30px 43px;
    }

    &__title-container {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
    }

    &__title {
        text-transform: uppercase;
        color: $titleColor;
        margin-left: 10px;
        margin-bottom: -1px;
    }

    &__table {
        table-layout: fixed;
        margin-right: 50px;
        width: auto;

        td:not(:last-child) {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            vertical-align: middle;
        }
    }

    &__out-of-date {
        color: $idleColor;
    }

    &__header {
        &-item {
            border-bottom: none !important; // overriding tables.scss
        }
    }

    &__subheader {
        width: 150px;
        &-leftSplit {
            border-right: none !important; // overriding tables.scss
        }
        &-rightSplit {
            border-left: none !important; // overriding tables.scss
        }
    }

    &__data-item {
        &--number {
            text-align: right;
        }
    }

    &__button {
        min-width: 152px;
        button {
            width: 100%;
            height: 100%;
            margin-bottom: 0;
            cursor: pointer;
            background-color: transparent;
            &:focus {
                outline: none;
                svg {
                    border-radius: 50%;
                    box-shadow: 0 0 5px $tertiaryColor;
                }
            }
        }
    }
}
