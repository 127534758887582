@import '../core-globals.scss';

.EditableCell {
    position: relative;
    padding: 0 18px 0 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    & > * {
        padding-right: 2px;
    }

    &--editable {
        padding: 0 0 0 4px;
    }

    &__edit-button-icon {
        position: absolute;
        right: 0;
        top: 0;
        border: none;
        cursor: pointer;
        background-color: transparent;
        color: $lightPrimaryColor;

        &:focus {
            outline: none;
            svg {
                border-radius: 50%;
                box-shadow: 0 0 5px $tertiaryColor;
            }
        }
        &:hover {
            svg {
                color: $errorColor;
            }
        }
        &--disabled {
            cursor: not-allowed;
        }
    }
}
