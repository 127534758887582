@import '../../core-globals.scss';

.SlicingFilters {
    display: flex;
    margin-top: 40px;
    &__select {
        width: 260px;
        margin-right: 20px;
        margin-bottom: 20px;
    }
    &__addButton,
    &__removeButton {
        font-size: 24px;
        cursor: pointer;
        text-align: center;
        border: none;
        background-color: transparent;
        padding: 5px 3px;

        &:focus,
        &:hover {
            outline: none;
            color: $tertiaryColor;
        }
    }
}
