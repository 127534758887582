@import '../core-globals.scss';

.SettingsStoreItem {
    font-family: $nikeFont;
    margin-right: 10px;

    &--primary {
        font-weight: 800;
    }

    &--secondary {
        color: $lightPrimaryColor;
        font-size: 16px;
    }
}
