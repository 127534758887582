@import '../core-globals.scss';

.PermissionList {
    ul {
        padding: 0;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        @media only screen and (min-width: $break-xlarge) {
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }
        column-gap: 12px;
        list-style: none;
    }
    &__item {
        &--all {
            .checklabel {
                text-transform: uppercase;
                font-weight: bold;
            }
        }
        &--inactive {
            opacity: 0.4;
        }
    }
}
