@import '../core-globals.scss';

.LateAdds {
    table {
        width: 100%;
    }
    &__remove {
        color: $warningColor;
        & > span {
            color: $errorColor;
            cursor: pointer;
            padding: 4px;
            margin-right: 4px;
            outline: none;
            &:active {
                opacity: 0.5;
            }
            &:hover {
                color: darken($errorColor, 10%);
            }
        }
        .svg-loader {
            display: inline-block;
        }
    }
}
