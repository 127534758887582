@import '../core-globals.scss';

.ClearSubFiltersButton {
    &__button {
        &-buy-plan-sub-filter {
            color: $activeColor;
            border: 1px solid $activeColor;
        }

        &--small {
            padding: 10px 5px;
            font-size: 12px;
            max-height: 20px;
            min-height: auto;
            min-width: auto;
        }

        &--disabled {
            color: #ccc;
            border: 1px solid #ccc;
            background: #eee;
            cursor: not-allowed;
            &:hover {
                color: #ccc;
                background: #eee;
                border: 1px solid #ccc;
            }
        }
    }

    &__icon {
        background-color: $activeColor;
        border-radius: 50%;
        border: none;
        height: 20px;
        width: 20px;
        color: $secondaryColor;
        padding: 0;

        &:hover {
            background-color: rgba($activeColor, 0.8);
        }

        svg {
            height: 11px;
        }

        &--disabled {
            color: #ccc;
            border: 1px solid #ccc;
            background: #eee;
            cursor: not-allowed;
            &:hover {
                color: #ccc;
                background: #eee;
                border: 1px solid #ccc;
            }
        }
    }
}
