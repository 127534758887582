@import '../core-globals.scss';

.ViewAssortmentList {
    overflow-x: hidden;
    position: relative;
    margin-top: 20px;

    .infinite-scroll-list {
        height: calc(100vh - 355px);
    }

    &__item {
        position: absolute;
        outline: 0;
    }

    &__container {
        display: flex;
        flex-direction: row;
    }

    &__fixed-container {
        contain: content;
        z-index: 1;
        box-shadow: 8px 0 5px -4px rgba(0, 0, 0, 0.1);
        .ViewAssortmentList__item {
            // Hide the Chrome scrollbar for the fixed container list but don't disable it; we still want it to scroll.
            &::-webkit-scrollbar {
                display: none;
            }
        }
    }

    &__scrollable-container {
        flex: 0 0 auto;
        overflow-x: scroll;
    }

    &__aggregatedMaterials {
        width: 100%;
        height: calc(100vh - 140px);
    }
    &__column {
        display: inline-block;
        padding: 2px 5px;
    }
    &__header,
    &__subheader {
        padding: 0 8px;
        text-transform: uppercase;
        height: 32px;
        line-height: 22px;
        border-bottom: 1px solid $borderColor;

        & .ViewAssortmentList__image {
            padding: 0;
            position: relative;
            left: -5px;
        }

        & .ViewAssortmentList__lastYear {
            color: $lightPrimaryColor;
            font-family: $nikeFont;
        }
    }
    &__subheader {
        box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
    }
    &__header {
        font-family: $nikeFont;
        display: flex;
        align-items: center;
        height: 215px;
        align-items: flex-end;
    }
    &--loading {
        .ViewAssortmentList__aggregatedMaterials {
            opacity: 0.4;
            pointer-events: none;
            user-select: none;
        }
    }
    &__toggleStores {
        margin-bottom: -10px;
    }
    &__header-cell {
        &--highlighted {
            background-color: $borderColor;
        }
        &--toggles {
            display: flex;
            align-items: center;
            justify-content: space-around;
        }
    }
    &__storeHeader {
        width: 48px;
        position: relative;
        display: flex;
        padding: 0.75rem 0;
        white-space: nowrap;
        height: 220px;
        text-transform: none;
    }
    &__clusterHeader {
        display: flex;
        align-items: flex-end;
        position: relative;
        white-space: nowrap;
        height: 220px;
        text-transform: none;
    }
    &__filteredCountsContainer {
        position: absolute;
        z-index: 3;
        width: 48px;
        bottom: 0;
        left: 0;
        border-left: 1px solid #e5e5e5;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 22px;
    }
    &__filteredCounts {
        background: #ccc;
        font-family: Helvetica, Arial, sans-serif;
        width: 80%;
        font-weight: 200;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 16px;
    }
    &__filteredCountsEmpty {
        width: 80%;
    }
    &__noResults {
        position: relative;
        top: 50%;
        width: 530px;
        margin: 0 auto;
        font-style: italic;
        font-size: 1.4em;
        line-height: 2em;
        text-align: center;
        padding: 30px;
        border: 2px dashed #e5e5e5;
        background-color: #f7f7f7;
    }
    &__noMaterials {
        z-index: 1;
        position: relative;
        top: 10%;
        width: 400px;
        margin: 0 auto;
        font-style: italic;
        font-size: 15px;
        line-height: 2em;
        text-align: center;
        padding: 30px;
        border: 2px dashed #e5e5e5;
        background-color: #f7f7f7;
        display: flex;
        flex-direction: column;
    }
}
