@import '../core-globals.scss';

.SyncImages {
    font-family: $nikeFont;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    margin-top: 10px;

    &__name {
        display: inline-block;
    }

    &__statusContainer {
        display: flex;
        flex-direction: column;
    }

    &__status {
        color: $lightPrimaryColor;
        margin-left: 0.75rem;

        &--success {
            color: $successColor;
        }

        &--error {
            color: $errorColor;
        }
    }

    &__syncDate {
        margin-left: 0.75rem;
        color: $lightPrimaryColor;
    }

    .Button {
        margin-bottom: 0;
        line-height: 1em;
        min-width: 170px;
    }
}
