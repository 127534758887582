@import '../core-globals.scss';

.HeaderRow {
    &__star {
        color: $starColor;
        margin-right: 2px;
        padding-bottom: 1px;
    }

    &__subfilter-popover {
        .MuiPopover-paper {
            padding: 20px;
        }
    }
}
