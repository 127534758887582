@import '../core-globals.scss';

.NewSeason {
    &__warningDialog {
        border-top: 4px solid $warningColor;
        border-bottom: 4px solid $warningColor;
        margin-bottom: 24px;
        h3 {
            color: $warningColor;
        }
    }
}
