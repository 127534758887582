@import '../core-globals.scss';

.SalesTargets {
    &__disclaimer {
        animation: fadeInAnimation 0.2s;
        margin-top: 6px;
    }
    .SalesTargetsTemplateButton {
        margin-bottom: 10px;
    }
}
