@import '../core-globals.scss';

.ConfirmButton {
    &__confirm {
        background-color: $errorColor;

        &:hover {
            background-color: $errorColor;
        }
    }
}
