@import '../../core-globals.scss';

.topStylesVariance {
    &__negative {
        color: $errorColor;
    }
    &__positive {
        color: $successColor;
    }
}
