@import '../core-globals.scss';

.SeasonForm {
    &__form {
        display: grid;
        grid-template-columns: 1fr 3fr;
        grid-gap: 1.6em 0.6em;
        grid-auto-flow: dense;
        align-items: center;

        > input,
        .input,
        .output {
            grid-column: 2 / 4;
            width: auto;
            margin: 0;
        }
        .input > div {
            padding: 0;
            width: 256px;
        }
        input {
            background-color: $secondaryColor;
            padding: 8px;
            border: 1px solid $lightPrimaryColor;
            &:focus {
                border: 1px solid $primaryColor;
                outline: none;
            }
        }
        label {
            width: auto;
            padding: 0;
            margin: 0;
        }
    }
    &__error {
        color: $errorColor;
    }
    &__success {
        color: $successColor;
    }
}
