@import '../core-globals.scss';

.SourceFileError {
    margin-bottom: 36px;
    &:last-of-type {
        margin-bottom: 46px;
    }
    ul {
        font-family: Courier New, Courier, monospace;
        margin-top: 5px;
        margin-bottom: 4px;
        border: 1px dashed $borderColor;
        padding: 7px 13px;
        list-style: none;
        display: grid;
        position: relative;
    }
    &__grid {
        grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    }
    &__title {
        font-family: $nikeFont;
        font-size: 1.2em;
    }
    &__moreErrors {
        font-family: $nikeFont;
        margin-right: 140px;
    }
    &__clipboard {
        position: absolute;
        right: -1px;
        bottom: -26px;
        button {
            height: 26px;
            border: 1px dashed $borderColor;
            cursor: pointer;
            &:hover {
                background-color: $backgroundColor;
            }
            &:active,
            &:focus {
                border-color: $lightPrimaryColor;
                outline: none;
            }
        }
    }
}
