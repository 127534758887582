.ToplineFilters {
    margin-top: 20px;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    &--filter {
        width: 260px;
        margin-right: 20px;
        margin-bottom: 20px;
    }
}
