@import '../core-globals.scss';

.HeaderColumn {
    &__header,
    &__editable,
    &__sortable,
    &__filterable {
        display: flex;
        align-items: center;
        outline: none;
        justify-content: space-between;

        &:hover {
            color: $tertiaryColor;
            background-color: $backgroundColor;
        }
    }

    &__wrap {
        display: flex;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    &__label {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-left: 6px;
    }

    &__button {
        cursor: pointer;
        &--icon {
            color: $inputBorderColor;
            font-size: 18px;
        }
        &--active {
            color: black;
        }
        &-edit {
            color: $primaryColor;
            font-size: 15px;
            margin-left: 6px;
        }
        &-filter {
            margin: 0 6px;
            font-size: 12px;

            &--filtered {
                color: $activeColor;
            }

            &--align-right {
                right: -5px;
            }
        }
    }
}
