.StoreTargets,
.AttributedSalesTargets {
    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__options {
        padding-bottom: 1rem;
        align-self: flex-end;
    }
}
