@import '../core-globals.scss';

.MarginImpact {
    display: grid;
    padding: 0 1rem;
    max-width: 1700px;
    margin: 0 auto;

    &__header {
        display: table;
        max-width: 800px;
    }

    &__noResults,
    &__missingFilters {
        font-style: italic;
        font-size: 1.4em;
        line-height: 2em;
        text-align: center;
        padding: 30px;
        border: 2px dashed $borderColor;
        justify-self: center;
        align-self: center;
        background-color: $backgroundColor;
    }

    &-filters {
        .main-filters {
            border-bottom: none;
            padding: 0.5rem 0 1.5rem 0;

            &__filter {
                margin-left: 0;
                margin-right: 2rem;

                & > div {
                    padding: 0;
                }
            }
        }
    }
}
