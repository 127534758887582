@import '../core-globals.scss';

.DummyCodes {
    table {
        width: 100%;
    }

    &__verified-feedback {
        display: inline-block;
        margin: 0 20px;
    }

    & .ErrorMessage {
        color: $errorColor;
        display: flex;
        align-items: center;

        &__body {
            display: inline-block;
            margin-left: 10px;
        }
    }
}
