@import '../core-globals.scss';

.subheader-row {
    font-weight: bold;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 1;
    padding: 0 10px;
    text-transform: uppercase;
    height: 32px;
    line-height: 22px;
    border-bottom: 1px solid $borderColor;

    &__disabled {
        color: $idleColor;
    }
    &__enabled {
        color: $primaryColor;
    }

    &__totals-update {
        color: $buttonBackgroundHover;
    }

    &__item {
        display: inline-block;
        cursor: default;
        padding: 4px 5px;

        &--align-right {
            text-align: right;
        }
        &--highlighted {
            background-color: rgba($borderColor, 0.7);
        }
        &-favoriteStore {
            padding: 0;
            font-family: $nikeFont;
            & > * {
                display: inline-block;
            }
        }
        &-lastYear {
            padding: 0;
            color: $lightPrimaryColor;
            font-family: $nikeFont;
        }
    }

    &__openToBuyUnits,
    &__aaOpenToBuyUnits,
    &__afOpenToBuyUnits {
        &--item-value,
        &--item-tooltip {
            display: inline-block;
        }
    }

    &__buyRetail,
    &__salesRetail,
    &__salesUnits,
    &__buyWholesale,
    &__presentationStocks {
        &--item-value,
        &--item-tooltip {
            display: inline-block;
        }
    }

    &__lastYearSalesUnits {
        color: rgba(0, 0, 0, 0.4);
    }

    &__aa-af-tooltip {
        text-align: left;
    }
}
