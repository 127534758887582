@import '../core-globals.scss';

.bulkUpdate {
    &__message {
        display: flex;
        line-height: 1.4em;
    }
    &__inputs {
        display: flex;
    }
    &__valueInput {
        width: 100px;
        margin-right: 20px;
        text-align: right;
        &--label {
            font-size: 12px;
            font-weight: bold;
            margin-bottom: 5px;
        }
        input {
            width: 100%;
            height: 26px;
            text-align: inherit;
            font-size: 14px;
        }
    }
    &__methodInput {
        label {
            font-size: 12px;
            font-weight: bold;
            line-height: 14px;
            margin-bottom: 5px;
            transform: translate(0, 0) scale(1);
            width: 100%;
            transform-origin: initial;
            text-align: right;
            top: 1px;
            transition: none;
        }
    }
    &__warning,
    &__explanation,
    &__note {
        font-size: 12px;
        margin-top: 20px;
        color: $titleColor;
    }
}
