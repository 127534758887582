@import '../core-globals.scss';

.ExchangeRates {
    width: 50%;
    max-width: 750px;
    margin: 0 auto;
    h1 {
        .svg-loader {
            display: inline;
            margin-left: 8px;
        }
    }
    &__error {
        color: $errorColor;
    }

    &__buttonRow {
        justify-content: space-between;
    }
    &__buttonContainer {
        margin-bottom: 20px;
    }
    &__snapshotData {
        margin: 5px;
        text-align: right;
    }
    .Button {
        margin: 5px;
    }
    .Button__disabled {
        cursor: not-allowed;
        pointer-events: none;
        background: darken(#e5e5e5, 10%);
        svg {
            color: $lightPrimaryColor;
        }
        &:hover {
            svg {
                color: $lightPrimaryColor;
            }
        }
    }
    &__add {
        &.Button__disabled {
            background-color: $inputBorderColor;
            color: $secondaryColor;
        }
    }
    &__header {
        background: $buttonBackground;
        height: 44px;
        color: white;
        font-family: $nikeFont;
        align-items: center;
        text-transform: uppercase;
    }
    &__rate {
        border-top: 1px solid $borderColor;
        height: 44px;
        align-items: center;
    }
    &__label {
        font-family: $nikeFont;
        text-transform: uppercase;
    }
    &__hindsightValueContainer {
        border-top: 1px solid black;
        margin-top: 50px;
        margin-bottom: 20px;
        align-items: center;
        height: 44px;
    }
    &__report {
        float: right;
    }
}
