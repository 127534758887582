@import '../core-globals.scss';

.ChannelPermissions {
    &__permission-lists {
        h3 {
            margin-bottom: 20px;
        }
    }

    .Radio {
        margin-left: 20px;
    }
}
