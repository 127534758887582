@import '../core-globals.scss';

.SettingsModal {
    &__overlay {
        position: fixed;
        inset: 0px;
        background-color: rgba($secondaryColor, 0.8);
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 4;
        display: grid;
    }

    &__container {
        position: relative;
        margin: auto;
        background-color: $secondaryColor;
        box-shadow: 0 0 50px rgba($color: #000000, $alpha: 0.5);
        width: 50vw;
        height: auto;
        padding: 10px 10px 10px 20px;
        max-height: 100vh;
        overflow: auto;

        > div {
            max-height: 100%;
        }
    }

    &__close {
        position: absolute;
        right: 10px;
        top: 10px;
        border: none;
        font-size: 24px;
        cursor: pointer;

        &:hover,
        &:focus {
            color: $tertiaryColor;
        }
    }

    &__display-columns {
        margin: 25px 10px;
    }

    &__tab-title {
        font-family: $nikeFont;
    }

    &__order-stores-message {
        margin: 30px 30px 10px;
        font-size: 16px;
    }

    .SettingsDisplayColumns__list {
        max-height: calc(100vh - 200px);
        overflow-y: auto;
    }
}
