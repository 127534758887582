.infinite-scroll-list {
    width: 100%;
    height: calc(100vh - 302px);
    position: relative;

    // Needed to keep AutoSizer in sync while scrolling
    .scroll-smoother {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        will-change: scroll-position;
        pointer-events: none;
        z-index: 5;
    }
}
