@import '../core-globals.scss';

.Toggle {
    display: inline-block;
    &__button {
        font-size: 18px;
        min-width: 40px;
        height: 40px;
        border-top: 1px solid $borderColor;
        border-right: 1px solid $borderColor;
        border-bottom: 1px solid $borderColor;
        border-left: 1px solid $borderColor;
        padding: 0 0.5rem;
        cursor: pointer;

        &:hover {
            background-color: $backgroundColor;
        }
        &:focus {
            outline: none;
        }
        &:disabled {
            opacity: 0.5;
        }
        &--active {
            background-color: $primaryColor;
            color: $secondaryColor;
            border: 1px solid $primaryColor;
            &:hover {
                background-color: $primaryColor;
            }
        }
    }
}
