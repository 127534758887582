@import '../core-globals.scss';

.SourceFileOptions {
    display: grid;
    position: absolute;
    grid-template-rows: auto;
    grid-template-columns: auto 100px 100px;
    width: 200px;
    right: 0;
    height: 100%;
    grid-template-areas: '. reupload delete';
    &__reupload {
        grid-area: reupload;
    }
    &__delete {
        grid-area: delete;
    }
    &__reuploadbutton,
    &__deletebutton {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        border: none;
        color: $secondaryColor;
        cursor: pointer;
        svg {
            font-size: 2em;
            margin-bottom: 6px;
        }
    }
    &__reuploadbutton {
        background-color: $tertiaryColor;
        &:hover {
            background-color: darken($tertiaryColor, 5%);
        }
        &:active {
            background-color: darken($tertiaryColor, 7%);
        }
    }

    &__deletebutton {
        border-radius: 0 4px 4px 0;
        background-color: $errorColor;
        &:hover {
            background-color: darken($errorColor, 5%);
        }
        &:active {
            background-color: darken($errorColor, 7%);
        }
    }
}
