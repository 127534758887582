@import '../core-globals.scss';

.BuyplanRateOfSaleTooltip {
    // Due to the black background use brighter colors than the standard $successColor and $errorColor
    &--positive {
        color: #0f8;
    }

    &--negative {
        color: #f66;
    }
}
