@import '../../core-globals.scss';

$IMAGE_WIDTH: 35px;
$MATERIAL_WIDTH: 105px;
$DESCRIPTION_WIDTH: 250px;
$DESCRIPTION_DISTANCE: $IMAGE_WIDTH + $MATERIAL_WIDTH;

.TopStylesTable {
    &__table {
        table-layout: fixed;
        border-collapse: inherit;
    }

    &__container {
        overflow: auto;
    }

    &__columns {
        width: 140px;

        &-image {
            width: 35px;
        }
        &-material {
            width: $MATERIAL_WIDTH;
        }
        &-styleDescription {
            width: $DESCRIPTION_WIDTH;
        }
        &-revenue {
            width: 160px;
        }
        &-unitSalesTtlAsp {
            width: 140px;
        }
        &-StoresWosRos {
            width: 50px;
        }
    }

    &__noResults {
        font-style: italic;
        line-height: 2rem;
    }

    &__header {
        th {
            top: 0;
            border-top: none;
        }

        &-data ~ &-data {
            border: none;
            border-left: 1px solid $borderTableColor;
            border-bottom: 1px solid $borderTableColor;
        }
    }

    &__footer {
        text-align: right;
        font-weight: bold;
    }

    // All first 3 columns (headers and data)
    td:nth-child(-n + 3),
    th:nth-child(-n + 3) {
        position: sticky;
        width: 100%; // Ensures longer text gets visible
        z-index: 3;
        background-color: white;
    }

    // Image
    td:first-child,
    th:first-child {
        padding: 0;
        margin: 0;
        left: 0;
        min-width: $IMAGE_WIDTH;
    }

    // Material
    td:nth-child(2),
    th:nth-child(2) {
        left: $IMAGE_WIDTH;
        min-width: $MATERIAL_WIDTH;
    }

    // Description
    td:nth-child(3),
    th:nth-child(3) {
        left: $DESCRIPTION_DISTANCE;
        min-width: $DESCRIPTION_WIDTH;
        border-right: 3px solid $borderTableColor;
    }

    thead {
        z-index: 2;

        th {
            background-color: $secondaryColor;
            border-bottom: 1px solid $borderColor;
        }

        .TopStylesTable__subheader {
            th {
                padding: 0.4rem 0.75rem;
                background-color: $secondaryColor;
                text-align: right;
                border-bottom: 0;
            }

            box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);

            .SortableColumn {
                &__editable,
                &__sortable,
                &__filterable {
                    cursor: pointer;
                    outline: none;

                    &:hover {
                        color: $tertiaryColor;
                    }
                }

                &__button {
                    display: inline-block;
                    margin-right: 6px;

                    &--icon {
                        color: $inputBorderColor;
                        font-size: 18px;
                    }
                    &--active {
                        color: black;
                    }
                    &-filter {
                        margin-left: 6px;
                        position: absolute;
                        font-size: 12px;

                        &--filtered {
                            color: $activeColor;
                        }
                    }
                }
            }
        }
    }

    .TopStylesTable__footer {
        border-top: 2px solid $borderColor;

        tr:last-of-type td {
            border-bottom: 1px solid $borderColor;
        }
    }
}
