@import '../../core-globals.scss';

.DynamicRollupsTable {
    &__subheader-cell {
        display: flex;
        padding-top: 5px;
        flex-direction: column;
        align-items: start;
        font-weight: bold;
    }

    &__subheader-totals {
        font-family: $regularFont;
        padding-top: 10px;
        margin-left: 6px;
    }

    &__subheader-totals--negative {
        color: $errorColor;
    }

    &__subheader-row {
        height: 64px;
    }
}
