@import '../core-globals.scss';

tr.LateAddMaterial {
    td {
        vertical-align: middle;
    }
    td.LateAddMaterial {
        &--noPadding {
            padding: 0;
        }
        &__image {
            background-color: $secondaryColor;
            text-align: center;
        }
    }
}
