@import '../core-globals.scss';

.MarginImpactRow {
    td {
        vertical-align: middle;
    }
    td.MarginImpactRow {
        &--minimal-padding {
            padding: 0.3rem;

            > * {
                margin-right: 0.3rem;
            }
        }
    }
    &__input {
        text-align: right;
    }
}
