@import '../core-globals.scss';

.ViewAssortmentRow {
    border-bottom: 1px solid $borderColor;
    margin: 0 -2px;
    transition: background-color 0.5s linear;

    &__material {
        padding: 8px 10px 0;
        height: 48px;
        display: flex;
    }

    &--opened {
        background-color: $backgroundColor;
        padding-bottom: 10px;
    }
    &--loading {
        pointer-events: none;
        input {
            opacity: 0.5;
        }
    }
    &--highlighted {
        background-color: rgba($lightPrimaryColor, 0.3);
    }

    &__cell {
        height: 48px;
        line-height: 22px;
        padding: 5px;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        input {
            width: 100%;
            text-align: inherit;
        }

        &--align-right {
            text-align: right;
        }

        &--error {
            color: $errorColor;
        }
    }
    &__toggleStores {
        text-align: center;
        button {
            margin-top: -10px;
            width: 100%;
            height: 100%;
            border: none;
            cursor: pointer;
            background-color: transparent;
            padding-left: 0;
            &:focus {
                outline: none;
                svg {
                    border-radius: 50%;
                    box-shadow: 0 0 5px $tertiaryColor;
                }
            }
        }
    }
    &__image {
        padding: 0;
        .MaterialImage__thumbnail {
            margin: 0;
        }
    }
    &__materialCode--container {
        display: flex;
    }
    &__sellThrough,
    &__afPercentage,
    &__weeksOnSale,
    &__rateOfSale,
    &__beginningOfPeriodUnits {
        padding-top: 2px;
        input {
            &::placeholder {
                color: $lightPrimaryColor;
            }
        }
    }

    &__reviewedStatus {
        text-align: center;
    }

    &__AggregatedMaterialReviewedStatus__icon {
        cursor: pointer;
    }
    &__storesFlowRange,
    &__digitalFlowRange {
        .MuiSelect-root {
            text-transform: initial;
        }
    }
    &__storesFlowRangeContainer,
    &__digitalFlowRangeContainer {
        display: flex;
    }
}
