@import '../core-globals.scss';

@mixin colored-tooltip($color) {
    .tooltip-inner {
        background-color: $color;
    }

    // Did not find a cleaner way, the $self trick doesn't seem to work
    &.tooltip--top .arrow::before {
        border-top-color: $color;
    }
    &.tooltip--bottom .arrow::before {
        border-bottom-color: $color;
    }
    &.tooltip--left .arrow::before {
        border-left-color: $color;
    }
    &.tooltip--right .arrow::before {
        border-right-color: $color;
    }
}

.tooltip {
    z-index: 1301; // modal z-index is 1300
    &--error {
        @include colored-tooltip($errorColor);
    }

    &--info {
        @include colored-tooltip($tertiaryColor);
    }

    &--warning {
        @include colored-tooltip($warningColor);

        .tooltip-inner {
            color: $primaryColor;
        }
    }

    &--success {
        @include colored-tooltip($successColor);
    }

    &--active {
        @include colored-tooltip($activeColor);
    }
}
